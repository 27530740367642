<script lang="ts" setup>
	import { SpeedInsights } from "@vercel/speed-insights/vue";

	useHead({
		titleTemplate: (input) => {
			return input ? `${input} - Mimicmo` : "Mimicmo";
		},
	});
</script>

<template>
	<SpeedInsights />

	<main class="admin">
		<slot />
	</main>
</template>

<style scoped lang="scss">
	main {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		//background: var(--yellow);
	}
</style>
